<template>
    <b-sidebar
        id="add-new-user-sidebar"
        :visible="isAddNewUserSidebarActive"
        bg-variant="white"
        sidebar-class="sidebar-lg"
        shadow
        backdrop
        no-header
        right
        @hidden="resetForm"
        @change="(val) => $emit('update:is-add-new-user-sidebar-active', val)"
    >
        <template #default="{ hide }">
            <!-- Header -->
            <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
                <h5 class="mb-0">
                    <strong>AGREGAR NUEVO CLIENTE</strong>
                </h5>

                <feather-icon
                    class="ml-1 cursor-pointer"
                    icon="XIcon"
                    size="16"
                    @click="hide"
                />

            </div>

            <!-- BODY -->
            <validation-observer
                #default="{ handleSubmit }"
                ref="refFormObserver"
            >
                <!-- Form -->
                <b-form
                    class="p-2"
                    @submit.prevent="handleSubmit(onSubmit)"
                    @reset.prevent="resetForm"
                >

                    <!-- Logo img -->
                    <div id="preview" v-if="userData.url">
                        <img class="img-fluid" :src="userData.url" width="70%" alt="image" />
                    </div>

                    <!-- Logo + File -->
                    <b-form-group
                        label="Logo"
                        label-for="logo"
                    >
                        <b-form-file
                            id="logo"
                            v-model="userData.logo"
                            :state="userData.stateLogo"
                            @change="onFileChange"
                            accept=".jpg,.jpeg,.png"
                            placeholder="Elija un archivo o suéltelo aquí ..."
                            drop-placeholder="Suelta el archivo aquí ...."
                        />
                    </b-form-group>

                    <!-- Customer -->
                    <validation-provider
                        #default="validationContext"
                        name="Name"
                        rules="required"
                    >
                        <b-form-group
                            label="Nombre de la empresa"
                            label-for="name"
                        >
                            <b-form-input
                                id="name"
                                v-model="userData.name"
                                :state="getValidationState(validationContext)"
                                trim
                            />

                            <b-form-invalid-feedback>
                                {{ validationContext.errors[0] }}
                            </b-form-invalid-feedback>
                        </b-form-group>
                    </validation-provider>

                    <!-- Ruc -->
                    <validation-provider
                        #default="validationContext"
                        name="Ruc"
                        rules="required|digits:11"
                    >
                        <b-form-group
                            label="RUC"
                            label-for="ruc"
                        >
                            <b-form-input
                                id="ruc"
                                v-model="userData.ruc"
                                :state="getValidationState(validationContext)"
                                trim
                            />

                            <b-form-invalid-feedback>
                                {{ validationContext.errors[0] }}
                            </b-form-invalid-feedback>
                        </b-form-group>
                    </validation-provider>

                    <!-- Form Actions -->
                    <div class="d-flex mt-2">
                        <b-button
                            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                            variant="primary"
                            class="mr-2"
                            type="submit"
                        >
                            Agregar
                        </b-button>
                        <b-button
                            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                            type="button"
                            variant="outline-secondary"
                            @click="hide"
                        >
                            Cancelar
                        </b-button>
                    </div>

                </b-form>
            </validation-observer>
        </template>
    </b-sidebar>
</template>

<script>
    import { useToast } from 'vue-toastification/composition';
    import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
    import {
        BSidebar, BForm, BFormGroup, BFormInput, BFormFile, BFormInvalidFeedback, BButton,
    } from 'bootstrap-vue'
    import { ValidationProvider, ValidationObserver } from 'vee-validate'
    import { ref } from '@vue/composition-api'
    import { required, alphaNum, email, digits } from '@validations'
    import formValidation from '@core/comp-functions/forms/form-validation'
    import Ripple from 'vue-ripple-directive'
    import vSelect from 'vue-select'
    import store from '@/store'

    export default {
        components: {
            BSidebar,
            BForm,
            BFormGroup,
            BFormInput,
            BFormInvalidFeedback,
            BButton,
            BFormFile,
            vSelect,

            // Form Validation
            ValidationProvider,
            ValidationObserver,
        },
        directives: {
            Ripple,
        },
        model: {
            prop: 'isAddNewUserSidebarActive',
            event: 'update:is-add-new-user-sidebar-active',
        },
        props: {
            isAddNewUserSidebarActive: {
                type: Boolean,
                required: true,
            },
        },
        data() {
            return {
                required,
                alphaNum,
                email,
                digits,
            }
        },
        setup(props, { emit }) {

            // Use toast
            const toast = useToast();

            const blankUserData = {
                name: '',
                ruc: '',
                logo: null,
                url: null,
            }

            const userData = ref(JSON.parse(JSON.stringify(blankUserData)))
            const resetuserData = () => {
                userData.value = JSON.parse(JSON.stringify(blankUserData))
            }

            const onSubmit = () => {
                store.dispatch('app-user/addCustomer', userData.value)
                .then((response) => {
                    emit('refetch-data');
                    toast({
                        component: ToastificationContent,
                        props: {
                            title: response.data.message,
                            icon: 'CheckIcon',
                            variant: 'success',
                        },
                    });

                    emit('update:is-add-new-user-sidebar-active', false);
                })
                .catch( () => {
                    toast({
                        component: ToastificationContent,
                        props: {
                            title: 'Error al crear al Cliente',
                            icon: 'AlertTriangleIcon',
                            variant: 'danger',
                        },
                    });
                });
            }

            const onFileChange = (e) => {
                const file = e.target.files[0];
                if (file) {
                    userData.value.logo = file;
                    userData.value.url = URL.createObjectURL(file);
                    userData.value.stateLogo = Boolean(userData.value.logo);
                } else {
                    userData.value.logo = null;
                    userData.value.stateLogo = null;
                    userData.value.url = null;
                }
            };

            const {
                refFormObserver,
                getValidationState,
                resetForm,
            } = formValidation(resetuserData)

            return {
                userData,
                onFileChange,
                onSubmit,

                refFormObserver,
                getValidationState,
                resetForm,
            }
        },
    }
</script>

<style lang="scss">
    @import '@core/scss/vue/libs/vue-select.scss';

    #add-new-user-sidebar {
        .vs__dropdown-menu {
            max-height: 200px !important;
        }
    }

    #preview {
        text-align: center;
        margin-bottom: 1rem;
    }
</style>
